import React, { useEffect, useState } from 'react';
import './App.css';
import { db } from './firebase';
import { doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';
import { Analytics } from "@vercel/analytics/react"

function App() {
    const [countdown, setCountdown] = useState('');
    const [requestCount, setRequestCount] = useState(null);
    const [newYear, setNewYear] = useState(false);

    const fetchRequestCount = async () => {
        try {
            const docRef = doc(db, 'requests', 'requestCount');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                await updateDoc(docRef, {
                    count: increment(1)
                });
                const updatedDoc = await getDoc(docRef);
                setRequestCount(`Requests sent: ${updatedDoc.data().count}`);
            } else {
                await setDoc(docRef, { count: 1 });
                setRequestCount("Requests sent: 1");
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setRequestCount("Error loading data");
        }
    };

    const updateCountdown = () => {
        const now = new Date();
        const newYearDate = new Date(now.getFullYear() + 0, 0, 1);

        if (now >= newYearDate) {
            setNewYear(true);
        } else {
            const diff = newYearDate - now;
            const days = Math.floor(diff / (1000 * 60 * 60 * 24));
            const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
            const seconds = Math.floor((diff % (1000 * 60)) / 1000);

            setCountdown(`${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`);
        }
    };

    const triggerFireworks = () => {
        const now = new Date();
        const hours = now.getHours();
        const moscowOffset = -now.getTimezoneOffset() / 60 + 3; // Adjust to Moscow time
        const moscowHours = (hours + moscowOffset) % 24;

        const fireworksContainer = document.createElement('div');
        fireworksContainer.className = 'fireworks-container';
        document.body.appendChild(fireworksContainer);

        const fireworksCount = moscowHours >= 0 && moscowHours < 2 ? 10 : moscowHours >= 2 && moscowHours < 3 ? 5 : 3;

        for (let i = 0; i < fireworksCount; i++) {
            setTimeout(() => {
                const firework = document.createElement('div');
                firework.className = 'firework';
                firework.style.left = `${Math.random() * 100}vw`;
                firework.style.animationDelay = `${Math.random() * 1}s`;
                fireworksContainer.appendChild(firework);

                firework.addEventListener('animationend', () => {
                    firework.remove();
                });
            }, i * 300);
        }

        setTimeout(() => {
            fireworksContainer.remove();
        }, 5000);
    };

    const createSnowflake = () => {
        const snowflakes = document.querySelectorAll('.snowflake');
        if (snowflakes.length > 100) return;  // Ограничиваем количество снежинок до 100 для оптимизации

        for (let i = 0; i < 10; i++) {  // Увеличиваем количество снежинок за раз
            const snowflake = document.createElement('div');
            snowflake.className = 'snowflake';
            snowflake.innerHTML = '❄';
            snowflake.style.left = Math.random() * 100 + 'vw';
            snowflake.style.fontSize = Math.random() * 10 + 15 + 'px';
            snowflake.style.animationDuration = Math.random() * 5 + 3 + 's';
            document.body.appendChild(snowflake);

            snowflake.addEventListener('animationend', () => {
                snowflake.remove();
            });
        }
    };

    useEffect(() => {
        fetchRequestCount();
        const countdownInterval = setInterval(updateCountdown, 1000);

        // Используем requestAnimationFrame для создания снежинок
        const snowflakeInterval = () => {
            createSnowflake();
            requestAnimationFrame(snowflakeInterval);  // Повторяем создание снежинок
        };

        if (newYear) {
            clearInterval(countdownInterval);
            setInterval(triggerFireworks, 5000);
        }

        requestAnimationFrame(snowflakeInterval);  // Инициализируем первый кадр снежинок

        return () => {
            clearInterval(countdownInterval);
        };
    }, [newYear]);

    return (
        <div className="App">
            <Analytics/>
            {!newYear ? (
                <>
                    <h1>Coming Soon...</h1>
                    <h2>Countdown to New Year:</h2>
                    <div className="countdown">{countdown}</div>
                </>
            ) : (
                <h1 className="new-year">Happy New Year!</h1>
            )}
            <div className="request-counter">
                {requestCount === null ? (
                    <div className="spinner"></div>
                ) : (
                    requestCount
                )}
            </div>
        </div>
    );
}

export default App;